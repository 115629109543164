<template>
    <div class="typeselect-form">
        <div class="type">
            <RadioButton name="type" value="telefone" v-model="typeSelect" />
            <span class="cursor-pointer" @click="typeSelect = 'telefone'">Telefone</span>
        </div>
        <div class="type">
            <RadioButton name="type" value="email" v-model="typeSelect" />
            <span class="cursor-pointer" @click="typeSelect = 'email'">Email</span>
        </div>
    </div>
</template>

<script>
import { onMounted, ref, computed, watch } from "vue";

export default {
    props: ["modelValue", "msgErros", "submit"],
    emits: ["update:modelValue" ],
    setup(props, { emit }) {
        const mounted = ref(false);
        const typeSelect = ref("");

        const changeType = (type) => {
            if(mounted.value){
                emit("update:modelValue", type)
            }
        }

        watch(() => typeSelect.value, (newValue, oldValue) => {
            if(mounted.value ){
                changeType(newValue);
            }
        });

        onMounted(async () => {
            if(props.modelValue){
                typeSelect.value = await props.modelValue;
            }
            mounted.value = await true;
        })


        return{
            typeSelect,
            changeType
        }
    },
};
</script>

<style lang="scss" scoped>
.typeselect-form {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 12px 0;
    gap: 16px;
    margin-top: 12px;
    .type{
        display: flex;
        align-items: center;
        gap: 8px;
    }
}

::v-deep(.p-inputtext) {
    color: #2D313D;
    padding: 0 16px;
    height: 50px;
    border-radius: 8px;
    text-transform: lowercase;
    
    &::-webkit-input-placeholder { /* Chrome, Safari */
        text-transform: lowercase;
    }

    &:-moz-placeholder { /* Firefox 18- */
        text-transform: lowercase; 
    }

    &::-moz-placeholder {  /* Firefox 19+ */
        text-transform: lowercase;
    }

    :-ms-input-placeholder {  /* IE */
        text-transform: lowercase; 
    }
    
    &::placeholder{
        color: #BDBDBD;
        text-transform: none;
    }
}
</style>