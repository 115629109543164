<template>
    <div class="form">
        <div class="header">
            <h1>Localizar conta</h1>
        </div>

        <div class="limit">
            <p class="text-sendsms mt-16">Insira seu e-mail ou telefone para procurar sua conta.</p>
            <!-- {{type}} -->
            <TypeSelect v-model="type" />

            <!-- <ul>
                <li>phone: {{phone}}</li>
                <li>email: {{email}}</li>
            </ul> -->

            <PhoneForm v-model="phone" :submit="submit" :msgErros="msgErros" v-if="type === 'telefone'" />
            <EmailForm v-model="email" :submit="submit" :msgErros="msgErros" v-else-if="type === 'email'" />           

            <div class="flex flex-column w-full mt-2">
                <Button class="btn-submit" :disabled="loadingSubmit" @click="submit">
                    Continuar
                    <i class="pi pi-spin pi-spinner" v-if="loadingSubmit" />
                </Button>
                <Button class="btn-cancel" label="Cancelar" @click="cancel" />
            </div>
        </div>
    </div>
</template>

<script>
import { onMounted, ref, reactive, watch, computed } from "vue";
import { useRouter } from "vue-router";
import TypeSelect from "./components/TypeSelect"
import PhoneForm from "./components/PhoneForm"
import EmailForm from "./components/EmailForm"
import { FindUserAccount } from "./actions"

export default {
    //   props: ["",],
    components: {
        PhoneForm,
        EmailForm,
        TypeSelect,
    },
    setup(props, { emit }) {
        let router = useRouter();
        const mounted = ref(false)
        const email = ref("");
        const phone = ref("");
        const type = ref("telefone");
        const msgErros = ref("")
        const loadingSubmit = ref(false)

        const getValue = () => {
            if(type.value === "telefone") return phone.value
            if(type.value === "email") return email.value

            return ""
        }

        const submit = async () => {
            loadingSubmit.value = true;
            const res = await FindUserAccount(getValue());
            console.log(res);
            if(res.success){
                const data = JSON.parse(res.data);
                console.log('deu sucesso.')
                console.log(data);
            }else{
                const data = JSON.parse(res.data);

                msgErros.value = data.user[0]
            }
            loadingSubmit.value = false;
        };

        const cancel = () => {
            router.push("/account/login")
        }

        const searchAccount = () => {
            router.push("/account/recover/searchAccount")
        }

        watch(() => type.value, (newValue, oldValue) => {
            if( mounted.value ){
                email.value = ""
                phone.value = ""
                msgErros.value = ""
            }
        });

        onMounted(async () => {
            mounted.value = true;
        })

        return {
            email,
            phone,
            submit,
            cancel,
            msgErros,
            searchAccount,
            type,
            loadingSubmit,
        }
    },
};
</script>


<style lang="scss" scoped>
.form {
    display: flex;
    flex-direction: column;
    width: 478px;
    height: max-content;
    background: white;
    margin: 0 auto;
    margin-top: 30px;
    border-radius: 8px;
    padding-bottom: 32px;

    .btn-cancel{
        color: #2F80ED;
        background: white;
        border: 1px solid white;
        box-shadow: none;
        &:hover{
            background: white !important;
            border: 1px solid white !important;
            box-shadow: none;
        }
    }


    h1 {
        font-style: normal;
        font-weight: 700;
        font-size: 34px;
        text-align: center;
        margin: 0;
        margin-bottom: 30px;
    }

    .header {
        width: 100%;
        padding: 16px 0;
        border-bottom: 1px solid #F2F2F2;
        text-align: center;

        h1 {
            font-style: normal;
            font-weight: 400;
            font-size: 18px;
            margin: 0;
        }
    }

    .limit {
        width: 100% !important;
        padding: 0 32px;

        .localizar{
            text-align: center;
            margin-top: 20px;
            cursor: pointer;
           
           
            font-family: Roboto;
            color: #2F80ED;
            font-weight: 600;
            font-size: 14px;
        }

        .text-sendsms {
            font-size: 14px;
            line-height: 22px;
            font-family: Roboto, sans-serif;
            font-weight: 400;
            color: #828282;
            text-align: center;
            margin: 0 auto;
        }

        .text-code {
            font-size: 14px;
            line-height: 22px;
            font-family: Roboto, sans-serif;
            font-weight: 400;
            color: #828282;
            text-align: center;
            margin: 0 auto;
            margin-top: 20px;
        }

        .ask-notnumber {
            font-size: 14px;
            line-height: 22px;
            font-family: Roboto, sans-serif;
            font-weight: 400;
            color: #2F80ED;
            text-align: center;
            margin: 0 auto;
            /* transform: translateY(-2px); */
            cursor: pointer;
        }
        .color-primary{
            color: #FF6A33 !important;
            letter-spacing: .3px;
        }

        .sms-container {
            width: max-content;
            margin: 0 auto;
            margin-top: 6px;
            text-align: center;
            .input-code{
                width: max-content;
                font-size: 18px;
                padding: 0;
                height: 55px;
                letter-spacing: 5px;
                font-weight: 600;
            }
        }

        .sms-codetext {
            margin-top: 16px;

            .time-code {
                font-size: 14px;
                line-height: 22px;
                font-family: Roboto, sans-serif;
                font-weight: 400;
                color: #828282;
            }
        }
        .btn-submit{
            display: flex;
            justify-content: center;
            align-items: center;
            gap: 6px;
            background: #2F80ED;
            border-color: #2F80ED;
        }
    }
}

@media (max-width: 580px) {
    .form {
        width: 100%;
    }
}

::v-deep(.p-button) {
    margin-top: 10px;
    padding: 20px 80px;
    width: 100%;
}

::v-deep(.btn-submit:disabled){
    background: #2F80ED !important;
    color: white !important;
}

::v-deep(.p-inputmask) {
    letter-spacing: 3px;
}

::v-deep(input) {
    height: 50px;
    padding: 0 16px;
    border-radius: 8px;
    font-family: Roboto, sans-serif;
    font-size: 14px !important;
    font-weight: 400 !important;
    letter-spacing: 1px !important;
    &:disabled{
        background: white;
        /* opacity: 1; */
        cursor: not-allowed !important;
    }
    &::placeholder{
        color: #BDBDBD;       
    }
}

::v-deep(.p-dropdown) {
    border-radius: 8px;
    font-family: Roboto, sans-serif;
    font-size: 14px !important;
    font-weight: 400 !important;
    letter-spacing: 1px !important;
    &:disabled{
        background: white;
        /* opacity: 1; */
        cursor: not-allowed !important;
    }
    &::placeholder{
        color: #BDBDBD;       
    }
}
</style>