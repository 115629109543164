import patientAccountService from "../../../core/services/patientAcountService";
import { 
    FindUserAccountRequest,
    GetInfoPatientRequest,
    SendPasswordRecoveryCodeToUserRequest,
} from "../../../core/grpc/generated/patientAccount_pb";

const FindUserAccount = async user => {
    try {
        const req = new FindUserAccountRequest();
        req.setUser(user);
        console.log('FindUserAccount => user: ', user)

        const res = await patientAccountService.findUserAccount(req);

        return res;
    } catch (error) {
        console.log(error);
    }
}

const GetInfoPatient = async teste => {
    try {
        const req = new GetInfoPatientRequest();
        req.setUser(teste);

        const res = await patientAccountService.getInfoPatient(req);

        return res;
    } catch (error) {
        console.log(error);
    }
}

const SendPasswordRecoveryCodeToUser = async teste => {
    try {
        const req = new SendPasswordRecoveryCodeToUserRequest();
        req.setUser(teste);

        const res = await patientAccountService.sendPasswordRecoveryCodeToUser(req);

        return res;
    } catch (error) {
        console.log(error);
    }
}

export {
    FindUserAccount,
    GetInfoPatient,
    SendPasswordRecoveryCodeToUser,
}