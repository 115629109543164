<template>
    <div class="phone-form">
        <Dropdown
            v-model="postalCode"
            :options="countryList"
            optionLabel="name"
            placeholder="Selecione o país"
            @change="updateCountryCode"
        />
        <InputMask
            v-if="postalCode.code === '+55'"
            v-model="phone"
            :class="conditionError.class"
            mask="( 99 ) 9 9999-9999"
            placeholder="( xx ) x xxxx - xxxx"
            @change="updateModel"
        />
        <InputMask
            v-else-if="postalCode.code === '+1'"
            v-model="phone"
            :class="conditionError.class"
            mask="( 999 ) 99999-9999"
            placeholder="( xxx ) xxxxx - xxxx"
            @change="updateModel"
        />
        <InputText
            :value="modelValue"
            type="email"
            :class="conditionError.class"
            placeholder="Telefone"
            :maxlength="60"
            :disabled="true"
            @keyup.enter="submit"
            @input="(e) => $emit('update:modelValue', e.target.value)"
            v-else
        />
        <div class="messages" v-if="conditionError.status">
            <p class="p-invalid">{{ msgErros }}</p>
        </div>
    </div>
</template>

<script>
import { onMounted, ref, computed, watch } from "vue";

const countryList = [
    { name: "Brasil +55", code: "+55" },
    { name: "Estados Unidos +1", code: "+1" },
];


export default {
    props: ["modelValue", "msgErros", "submit", "country_code"],
    emits: ["update:modelValue", "update:country_code"],
    setup(props, { emit }) {
        const postalCode = ref({name: "", code: ""});
        const phone = ref("");

        const validateEmail = email => {
            var re = /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/g;
            return re.test(email);
        }

        const conditionError = computed(() => {
            if(props.msgErros){
                return {status: true, class: "p-invalid" }
            }

            return { status: false, class: "" }
        });

        const updateCountryCode = (e) => {
            phone.value = ""
            emit("update:modelValue", "")
            // emit("update:country_code", e.value.code);
        }

        const updateModel = (e) => {
            const number = e.target.value;
            emit("update:modelValue", postalCode.value.code + number.replace(/[^0-9]/g, ""));
            props.submit()
        }

        return{
            postalCode,
            conditionError,
            countryList,
            phone,
            updateCountryCode,
            updateModel,
        }
    },
};
</script>

<style lang="scss" scoped>
.phone-form {
    display: flex;
    flex-direction: column;
    gap: 8px;
    width: 100%;
    .messages{
        font-size: 12px;
        margin-top: 4px;
        margin-left: 6px;
    }
}

::v-deep(.p-inputtext) {
    color: #2D313D;
    padding: 0 16px;
    height: 50px;
    border-radius: 8px;
}

::v-deep(.p-dropdown){
    border-radius: 8px;
    .pi{
        font-size: 12px;
        margin-right: 8px;
    }
}

::v-deep(.p-dropdown-label) {
    display: flex;
    align-items: center;
    height: 50px;
}
</style>