<template>
    <div class="email-form">
        <InputText
            :value="modelValue"
            :class="conditionError.class"
            type="email"
            placeholder="Email"
            :maxlength="60"
            @keyup.enter="submit"
            @input="(e) => $emit('update:modelValue', e.target.value)"
        />
        <div class="messages" v-if="conditionError.status">
            <p class="p-invalid">{{ msgErros }}</p>
        </div>
    </div>
</template>

<script>
import { onMounted, ref, computed, watch } from "vue"

export default {
    props: [ "modelValue", "msgErros", "submit" ],
    emits: [ "update:modelValue" ],
    setup(props, { emit }) {
        const email_used = ref("")

        const validateEmail = email => {
            var re = /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/g;
            return re.test(email)
        }

        const conditionError = computed(() => {
            if( props.msgErros ) {
                return { status: true, class: "p-invalid" }
            }

            return { status: false, class: "" }
        })

        return{
            conditionError,
            email_used,
        }
    },
};
</script>

<style lang="scss" scoped>
.email-form {
    width: 100%;
    .messages{
        font-size: 12px;
        margin-top: 4px;
        margin-left: 6px;
    }
}

::v-deep(.p-inputtext) {
    color: #2D313D;
    padding: 0 16px;
    height: 50px;
    border-radius: 8px;
    text-transform: lowercase;
    
    &::-webkit-input-placeholder { /* Chrome, Safari */
        text-transform: lowercase;
    }

    &:-moz-placeholder { /* Firefox 18- */
        text-transform: lowercase; 
    }

    &::-moz-placeholder {  /* Firefox 19+ */
        text-transform: lowercase;
    }

    :-ms-input-placeholder {  /* IE */
        text-transform: lowercase; 
    }
    
    &::placeholder{
        color: #BDBDBD;
        text-transform: none;
    }
}
</style>